import React from 'react';

import Link from './Link';
import '../styles/components/_components.pagination.scss';

const Pagination = ({ totalPages, baseUrl, templatePath = '/{page}/' }) => {
  return (
    <div className="c-pagination">
      {new Array(totalPages).fill(totalPages, 0).map((_, index) => {
        const pageURL = index ? `/page:${index + 1}/` : '';
        const href = `${baseUrl}${templatePath.replace('{page}', pageURL)}`.replace(/\/\//g, '/');
        return (
          <Link
            to={href}
            activeClassName="c-pagination__item--current"
            key={href}
            className="c-pagination__item"
          >
            {index + 1}
          </Link>
        );
      })}
    </div>
  );
};

export default Pagination;
