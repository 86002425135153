/* eslint-disable global-require */
import React, { useState } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import ModalVideo from 'react-modal-video';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/seo/SEO';
import Pagination from '../components/Pagination';
import useI18n, { replaceValues } from '../utils/useI18n';
import Hero from '../components/Hero';
import ContactButton from '../components/ContactButton';
import TestimonialPreview from '../components/TestimonialPreview';
import SelectResort from '../components/SelectResort';
import TimeshareVideo from '../components/images/TimeshareVideo';
import StopPropagation from '../components/StopPropagation';

const Testimonials = ({ pageContext: { switchLang, hotelURL, hotelId }, data }) => {
  const [visible, setVisible] = useState(false);
  const {
    allTestimonial: {
      records,
      pageInfo: { pageCount },
      totalCount,
    },
    testimonialsHeroExtras,
  } = data;
  const { t } = useI18n();
  const TestimonialHeroExtra = replaceValues(testimonialsHeroExtras.html, {
    urlTips: t('url.tips'),
  });
  return (
    <Layout switchLang={switchLang}>
      <SEO
        title={t('testimonials.meta.title')}
        altTitle={t('menu.testimonials')}
        description={t('testimonials.meta.description')}
        keywords={t('testimonials.meta.keywords')}
        pathname={t('url.testimonials')}
        webpage
      />
      <Hero
        secondary
        title={t('testimonials.hero.title')}
        callout={t('testimonials.hero.callout')}
        extra={
          <div>
            <div dangerouslySetInnerHTML={{ __html: TestimonialHeroExtra }} />
            <StopPropagation preventDefault>
              <a
                className="c-videoAboutButton noGatsbyLink"
                href="https://www.youtube.com/watch?v=rcZq6VE1ywo"
                onClick={() => {
                  if (window.gtag) {
                    window.gtag('event', 'reproducir', {
                      event_category: 'videos',
                      event_label: 'timeshare cancellation testimonial',
                      value: 1,
                    });
                  }
                  setVisible(true);
                }}
              >
                <TimeshareVideo />
                <span className="c-videoAboutButton__icon">
                  <CaretRightOutlined />
                </span>
              </a>
            </StopPropagation>
          </div>
        }
      />
      <div className="o-wrapper u-margin-top">
        <div className="u-margin u-align-center">
          <strong className="u-h4 u-margin-bottom-tiny">{t('testimonials.filter.title')}</strong>
          <SelectResort resortSelected={hotelId} />
          <span className="o-callout u-margin-top-small">
            {t('testimonials.filter.count', { count: totalCount }, false)}
          </span>
        </div>
        <ul className="o-list-bare u-10/12@desktop u-push-1/12@desktop">
          {records.map((record) => {
            const { Testimonial } = record;
            return (
              <li
                key={Testimonial.id}
                className="o-list-bare__item o-media o-media--small c-testimonialPreview"
              >
                <TestimonialPreview data={record} />
              </li>
            );
          })}
        </ul>
        <Pagination
          totalPages={pageCount}
          baseUrl={t('url.testimonials')}
          templatePath={hotelURL ? `/{page}/${hotelURL}/` : '/{page}/'}
        />
      </div>
      <ContactButton section />
      {typeof window !== 'undefined' && (
        <ModalVideo
          channel="youtube"
          isOpen={visible}
          videoId="8nBAVe9qrwY"
          onClose={() => setVisible(false)}
        />
      )}
    </Layout>
  );
};

export const query = graphql`
  query TestimonialsList($skip: Int!, $limit: Int!, $hotelIdRegex: String = "/.+/") {
    allTestimonial(
      skip: $skip
      limit: $limit
      filter: { Testimonial: { hotel_id: { regex: $hotelIdRegex } } }
    ) {
      totalCount
      records: nodes {
        Testimonial {
          content
          description
          id
          slug
          name
          title
          hotel_id
        }
        featuredImg {
          childImageSharp {
            fluid(maxWidth: 100, maxHeight: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      pageInfo {
        pageCount
      }
    }
    testimonialsHeroExtras: htmlFragment(name: { eq: "testimonialsHeroExtras" }) {
      html
    }
  }
`;

export default Testimonials;
