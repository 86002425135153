import React from 'react';
import { CaretDownFilled } from '@ant-design/icons';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import useI18n from '../utils/useI18n';
import Link from './Link';
import '../styles/components/_components.select.scss';

const SelectResort = ({ resortSelected }) => {
  const { t } = useI18n();
  const data = useStaticQuery(graphql`
      query ResortSelector {
          hotels: allHotel {
              nodes {
                  id
                  Hotel {
                      id
                      slug
                      titulo
                  }
                  featuredImg {
                      childImageSharp {
                          fixed(height: 32, width: 32, jpegQuality: 85) {
                              ...GatsbyImageSharpFixed
                          }
                      }
                  }
              }
          }
          pages: allSitePage(filter: {path: {regex: "/\\/hotel:.+/"}}) {
              nodes {
                  path
              }
          }
      }
  `);

  const baseURL = t('url.testimonials');
  const selected = resortSelected
    ? data.hotels.nodes.find(item => item.Hotel.id === resortSelected)
    : null;

  return (
    <div className="c-select">
      {selected ? (
        <div className="c-select__value">
          {selected.featuredImg && (
            <Img
              fixed={selected.featuredImg.childImageSharp.fixed}
              className="c-select__optionIcon"
              fadeIn={false}
              Tag="span"
            />
          )}
          <span>{selected.Hotel.titulo}</span>
        </div>
      ) : (
        <div className="c-select__placeholder">
          Select one resort to filter testimonials
        </div>
      )}
      <div className="c-select__indicator">
        <CaretDownFilled />
      </div>
      <ul className="c-select__options">
        <li>
          <Link
            to={`${baseURL}/`}
            className={`c-select__option ${!resortSelected
              ? 'c-select__option--selected'
              : ''} c-select__option--all`}
          >
            Show all testimonials
          </Link>
        </li>
        {data.hotels.nodes.filter(item => {
          return item && data.pages.nodes.filter(page => {
            return page.path.includes(`hotel:${item.Hotel.id}-${item.Hotel.slug}`);
          }).length;
        })
          .map(item => {
            return (
              <li key={item.id}>
                <Link
                  to={`${baseURL}/hotel:${item.Hotel.id}-${item.Hotel.slug}/`}
                  className={`c-select__option ${item.Hotel.id === resortSelected
                    ? 'c-select__option--selected'
                    : ''}`}
                >
                  {item.featuredImg && (
                    <Img
                      fixed={item.featuredImg.childImageSharp.fixed}
                      className="c-select__optionIcon"
                      Tag="span"
                    />
                  )}
                  <span>{item.Hotel.titulo}</span>
                </Link>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default SelectResort;
