import React from 'react';
import Img from 'gatsby-image';
import { RightCircleOutlined } from '@ant-design/icons';

import useI18n from '../utils/useI18n';
import useHotel from '../hooks/useHotel';
import Link from './Link';
import '../styles/components/_components.testimonialsPreview.scss';

const TestimonialPreview = ({ data }) => {
  const { Testimonial, featuredImg } = data;
  const { Hotel, featuredImg: HotelPic } = useHotel(Testimonial.hotel_id) || {};
  const { t } = useI18n();
  const baseURL = t('url.testimonials');
  const url = `${baseURL}/${Testimonial.id}-${Testimonial.slug}/`;
  return (
    <>
      <Link to={url} className="c-blockLink" />
      <div className="o-media__img c-testimonialPreview__img">
        <Img
          fluid={featuredImg.childImageSharp.fluid}
          fadeIn={false}
        />
      </div>
      <div className="o-media__body">
        <p
          dangerouslySetInnerHTML={{ __html: Testimonial.description }}
          className="c-testimonialPreview__content"
        />
        <div className="c-testimonialPreview__meta">
          <span className="c-testimonialPreview__name">
            {Testimonial.name}
          </span>
          {Hotel && (
            <span className="c-testimonialPreview__resort">
              <div className="c-testimonialPreview__resortPic">
                <Img fluid={HotelPic.childImageSharp.fixed} />
              </div>
              {Hotel.titulo}
            </span>
          )}
          <Link to={url} className="c-testimonialPreview__link">
            {t('readMore')} <RightCircleOutlined />
          </Link>
        </div>
      </div>
    </>
  );
};

export default TestimonialPreview;
