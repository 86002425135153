import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const StopPayments = () => {
  const data = useStaticQuery(graphql`
      query {
          placeholderImage: file(relativePath: { eq: "timeshare-cancellation-testimonial.webp" }) {
              childImageSharp {
                  fluid(maxWidth: 360) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `);

  return <Img fluid={data.placeholderImage.childImageSharp.fluid} Tag="span" fadeIn={false} />;
};

export default StopPayments;
